const helpers = {
  sum(value1, value2) {
    return +value1 + +value2;
  },
  replaceSubStr(str, from, to) {
    const rx = new RegExp(from, 'g');
    return str.replace(rx, (val, idx) => {
      const isFirst = idx === 1;
      const nextIsSingleChar = str[idx + 2] === from;
      const prevIsSingleChar = str[idx - 2] === from;
      if (nextIsSingleChar) {
        return to;
      } else if (prevIsSingleChar || isFirst) {
        return ' ';
      } else {
        return to;
      }
    });
  },
  titleCase(str, sign) {
    var dashToSign = helpers.replaceSubStr(str, '-', sign);
    return dashToSign.replace(/\b[a-z](?=[a-z]{2})/g, (letter) =>
      letter.toUpperCase()
    );
  },
  toObj(...str) {
    const result = str.splice(0, str.length - 1);
    return result;
  },
  isBiggest(arr, obj) {
    const result = Object.values(arr).sort((a, b) => b.length - a.length);
    return result[0] === obj;
  },
  onlyFirstWordBeforeDash(str) {
    return str.replace(/-\w*/g, '').toUpperCase();
  },
  toJson(obj) {
    return JSON.stringify(obj);
  },
  replace(str, a, b) {
    return str?.replace(new RegExp(a, 'g'), b);
  },
  trySetActive(val, currentPage) {
    if (val === currentPage) {
      return 'btn-active';
    }
  },
  getType(data, type, options) {
    return data.find((x) => x.label === 'type').value === type
      ? options.fn(this)
      : options.inverse(this);
  },
  ifeq(a, b, options) {
    return a == b ? options.fn(this) : options.inverse(this);
  },
  ifnoteq(a, b, options) {
    return a != b ? options.fn(this) : options.inverse(this);
  },
  renderList(arr) {
    return (function go(arr, nest) {
      return `<dl>${arr
        .map((el) => {
          return `${
            !nest ? `<dt><h3 itemprop="description">${el.title}</h3></dt>` : ''
          }
                                    ${el.data
                                      .map((item) => {
                                        return `<dd>${
                                          typeof item === 'object'
                                            ? item.title + go([item], true)
                                            : item
                                        }
                                        </dd>`;
                                      })
                                      .join('')}`;
        })
        .join('')}
                                </dl>`;
    })(arr);
  },
  renderGherkin(map) {
    const frontEndHelpers = require('./helpers');
    return frontEndHelpers.generateCourseHTML(map, 0);
  },
  getYear() {
    return new Date().getFullYear().toString();
  },
  concat(str1, str2) {
    return str1 + str2;
  },
  getFieldValue(fields, label) {
    return fields.find((field) => field.label === label)?.value;
  },
  switch(value, options) {
    this.switch_value = value;
    this.switch_break = false;
    return options.fn(this);
  },
  case(value, options) {
    if (value == this.switch_value) {
      this.switch_break = true;
      return options.fn(this);
    }
  },
  times(n, block) {
    var accum = '';
    for (var i = 0; i < n; ++i) accum += block.fn(i);
    return accum;
  },
};

module.exports = {
  helpers,
  set(exphbs) {
    return exphbs.create({
      helpers,
      defaultLayout: 'main',
      /* layoutsDir: 'src/views/layouts',
      partialsDir: 'src/views/partials', */
    });
  },
};
